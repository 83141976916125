import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import { IWinzoUSPComponentProps } from '../../../lib/types/components';
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../CustomImage/CustomImageComponent';

const WinzoUSPComponentV2 = (props: IWinzoUSPComponentProps) => {

    const {winzoUSPData,padding="18px 0px 0vw"} = props;

    return (
        winzoUSPData && Object.keys(winzoUSPData).length ?
            <H2TrapeziumBG>
                <style jsx>{`
                  .parentContainerUSP {
                    font-family: var(--font-primary);
                    padding: ${padding};
                    background-color: #ffffff;
                    font-family: var(--font-primary);
                  }

                  .headingParent {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                  }

                  .heading {
                    font-size: ${dynamicCssSize(60, 18)};
                    text-transform: uppercase;
                    font-weight: 900;
                    font-style: italic;
                    letter-spacing: 2.25px;
                    max-width: 65vw;
                    text-align: center;
                    color: #35255a;
                  }

                  .itemContainer {
                    padding: ${dynamicCssSize(65, 0)} 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                  }

                  .imgContainer {
                    position: relative;
                    height: ${dynamicCssSize(130, 42)};
                    width: 100%;
                  }

                  .content {
                    padding: ${dynamicCssSize(30, 10)} 0px;
                    color: rgba(51, 51, 51, 0.85);
                  }

                  .itemNumber {
                    font-size: ${dynamicCssSize(56, 18)};
                    font-weight: 900;
                    letter-spacing: -0.9px;
                    margin: 0;
                  }

                  .itemQuantity {
                    font-size: ${dynamicCssSize(32, 12)};
                    font-weight: 600;
                    line-height: 0.88;
                    letter-spacing: -0.51px;
                  }
                  
                  @media screen and (max-width: 767px) {
                    .heading {
                      line-height: 0.6;
                      letter-spacing: 1px;
                    }

                    .itemNumber {
                      font-style: italic;
                      font-size:80%;
                    }

                    .itemQuantity {
                      line-height: 0.68;
                      letter-spacing: normal;
                    }
                  }
                `}</style>
                <div className={"container-fluid parentContainerUSP"}>
                    <div className="container uspContainer">
                        <div className="headingParent">
                            <h2 className="heading">
                                {winzoUSPData.heading}
                            </h2>
                        </div>
                        <div className="row">
                            {winzoUSPData.imageData.map((item, index) => {
                                return (
                                    <div className={"col itemContainer"} key={"winzoUSPData.imageData_" + index} >
                                        <div className="imgContainer">
                                            {item.srcV2 && <CustomImageComponent
                                                src={item.srcV2}
                                                className="uspImg"
                                                alt={"winzo-features"}
                                                layout={"fill"}
                                                objectFit="contain"
                                                objectPosition={"center"}
                                            />}
                                        </div>
                                        <div className={"content"}>
                                            <p className="itemNumber">{item.text1}</p>
                                            <p className="itemQuantity">{item.text2}</p>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default WinzoUSPComponentV2;