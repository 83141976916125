import React from "react";

const H2TrapeziumBG = ({uniqueContainerId="",children})=> {
    return (
        <>
            <style jsx>{`
              .main-trapezium-container {
                position: relative;
                margin-top: -4.6vw;
              }

              .trapezium-bg-image {
                max-width: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 9;
              }

              .trapezium-bg-clip {
                clip-path: polygon(0 4.5vw,15.6% 4.5vw,19.3% 0,80.8% 0,84.4% 4.4vw,100% 4.5vw,100% 100%,0 100%)
              }
            `}</style>
            <div id={uniqueContainerId} className={"main-trapezium-container"}>
                <img
                    src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/home-v2-h2-bg.svg"
                    className={"trapezium-bg-image"}
                    alt={"trapezium shape"}
                />
                <div className={"trapezium-bg-clip"}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default H2TrapeziumBG;
